import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Avatar } from 'antd';
import { makePath } from "../helpers/path";
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';

const Play = () => {
    const loc = useLocation();
    const params = new URLSearchParams(loc.search);
    const gameParam = params.get('game');
    const paramsGameUrl = gameParam ? atob(gameParam) : '';
    const gamePathParam = params.get('path');
    const paramsGamePath = gamePathParam ? atob(gamePathParam) : '';

    const prefixState = useSelector((state: ApplicationState) => state.prefix);
    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    if (paramsGameUrl === '') {
        window.location.href = makePath(paramsGamePath);
    }

    const goBack = () => {
        if (paramsGamePath !== null) {
            window.location.href = makePath(paramsGamePath);
        }
    };

    const setElementHeight = () => {
        if (iframeRef.current) {
            const height = window.innerHeight - (window.innerWidth > 768 ? 56 : 48); // ปรับความสูงตามเงื่อนไข
            iframeRef.current.style.height = `${height}px`;
        }
    };

    useEffect(() => {
        setElementHeight();

        window.addEventListener('resize', setElementHeight);

        return () => {
            window.removeEventListener('resize', setElementHeight);
        };
    }, []);

    useEffect(() => {
        const handleIframeMessage = (event: MessageEvent) => {
            const message = event.data;
            if (message.type === 'Window.Redirect') {
                window.location.href = makePath(paramsGamePath)
            }
        };

        window.addEventListener('message', handleIframeMessage);

        return () => {
            window.removeEventListener('message', handleIframeMessage);
        };
    }, []);

    return (
        <>
            <div className="topbar-game">
                <div
                    onClick={goBack}
                    style={{ display: 'flex', fontSize: 20, padding: '8px 8px 8px 4px', cursor: 'pointer' }}
                >
                    <i className="fa-solid fa-arrow-left"></i>
                </div>
                <Link to={makePath('/wallet')}>
                    {prefixState.info?.logo ? (
                        <Avatar
                            className="avatar-logo avatar-logo-topbar"
                            src={prefixState.info?.logo}
                            shape="square"
                        />
                    ) : (
                        <div className="prefix-name">{prefixState.info?.prefix}</div>
                    )}
                </Link>
            </div>
            <iframe
                ref={iframeRef}
                src={paramsGameUrl}
                title="Game"
                className="iframe-game"
            ></iframe>
        </>

    );
};

export default Play;
